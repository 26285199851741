import useAuth from '@components/_hooks/useAuth';
import useGlobalSettings from '@components/_hooks/useGlobalSettings';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StyledBox from '@components/Custom/Box/StyledBox';
import useToggleState from '@components/_hooks/useToggleState';
import CustomDialog from '@components/Components/Reusables/CustomDialog';
import BasicAccountDialog from '@components/Components/Modals/BasicAccountDialog';
import { useEffect } from 'react';

function TypographyText({children, ...rest}){
    return (
        <Typography  
            className={`mr-1`} component={'span'} variant='contain' fontSize={14} 
            sx={{
                border:'none', 
                boxShadow:'none', 
                color: 'primary.main',
                cursor: 'pointer',
                marginLeft: '5px',
                marginTop: '1px',
                }}
            >
            {children}
        </Typography>
    )
}

const HeaderLoginText = () => {

    const user = useSelector(state => state.authReducer.user);
    const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
    const {getSetting} = useGlobalSettings();
    const {isAgent} = useAuth();
    const {open, setOpen, handleToggle} = useToggleState();

    useEffect(() => {
        if(open && !isAuthenticated){
            setOpen(false);
        }
    }, [open, isAuthenticated])
    

    const authenticatedText = () => {
        if(isAuthenticated){
          if(getSetting('crm_type', 'default') == 'basic' && !isAgent()){
            return `Hello, ${user.name}`;
          }
          return 'My Account';
        }
  
        return 'Login/Register';
    }
  
    const linkRoute = () => {
        if(user){
          if(isAgent()){
            return process.env.NEXT_PUBLIC_USER_CRM_ROUTE;
          }
          else if(getSetting('crm_type', 'default') != 'basic'){
            return process.env.NEXT_PUBLIC_USER_HOME_ROUTE;
          }
          else {
            return process.env.NEXT_PUBLIC_HOME_URL;
          }
        }
        return process.env.NEXT_PUBLIC_USER_LOGIN_URL;
    }
    

    if(getSetting('crm_type', 'default') == 'basic' && isAuthenticated && !isAgent()){
        return (
            <>
                {open && (
                    <CustomDialog
                        open={open}
                        handleClose={handleToggle}
                        title={`My Account`}
                        maxWidth="sm"
                    >
                        <BasicAccountDialog />
                    </CustomDialog>
                )}

                <StyledBox onClick={handleToggle} className='mr-2 mt-1 d-flex justify-space-between align-items-center flex-direction-row text-vertical text-vertical' >
                    <AccountCircleIcon sx={{color: 'primary.main',fontSize:28}}/>
                    <TypographyText>
                        {authenticatedText()}
                    </TypographyText>
                </StyledBox>
            </>
        )
    }

    return (
        <Link href={linkRoute()} className='text-vertical d-flex'>
            <StyledBox className='mr-2 mt-1 d-flex justify-space-between align-items-center flex-direction-row text-vertical text-vertical' >
                <AccountCircleIcon sx={{color: 'primary.main',fontSize:28}}/>
                <TypographyText>
                    {authenticatedText()}
                </TypographyText>
            </StyledBox>
        </Link>
    )
}

export default HeaderLoginText